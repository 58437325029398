@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 196 84% 46%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 196 84% 46%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: hsl(var(--border));
    border-radius: 5px;
  }
}

.ql-toolbar .ql-formats button {
  color: #333;
}

.ql-toolbar .ql-formats button:hover {
  color: #007bff;
}

.ql-container {
  border-radius: 0 0 0.75rem 0.75rem;
  font-size: 14px;
}

.ql-toolbar.ql-snow {
  border: none !important;
}
.ql-container.ql-snow {
  border: none !important;
}

.ql-container h1 {
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 0px;
  border-top: 1px solid #dfe1e6;
  font-size: 28px;
  font-weight: bold;
}
.ql-container h2 {
  margin-top: 18px;
  margin-bottom: 20px;
  position: relative;
  padding: 4px 0px 0px 16px;
  font-size: 22px;
  font-weight: bold;
}
.ql-container h2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  width: 4px;
  height: 36px;
  background: #007bff;
}
.ql-container h3 {
  margin-top: 20px;
  padding: 10px 10px;
  background-color: var(--color-gray);
  border-radius: 2px;
  font-size: 20px;
  font-weight: bold;
}
.ql-container h4 {
  margin-top: 10px;
  font-size: 18px;
  padding: 6px 0px;
  font-weight: bold;
}

.ql-editor {
  min-height: 64px;
  max-height: 320px;
  padding: 10px;
}
.ql-disabled .ql-editor {
  min-height: unset;
  max-height: unset;
  padding: 0px;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
  margin: 10px 0px 0px;
}

.ql-container ul > li,
.ql-container ol > li {
  margin: 2px 0 4px;
  line-height: 1.3;
}
